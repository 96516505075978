.articles-card {
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 15px;
}

.articles-card:hover {
  transform: scale(1.05);
}

.articles-card-img {
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
}

.articles-card-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
  line-height: 1.5;
}

.articles-card-subtitle {
  font-size: 16px;
  margin-top: 15px;
  text-overflow: ellipsis;
}
