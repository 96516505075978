.new-div {
  margin: auto;
  width: 98%;
  max-width: 800px;
}

.new-div.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-modal {
  height: 70vh;
  overflow: auto;
}

.new-form {
  width: 100%;
  margin: auto;
  margin-top: 30px;
}

.new-subtitle {
  margin-top: 10px;
  font-size: 16px;
}

.new-result-div {
  margin-top: 30px;
  margin: auto;
  width: 100%;
}

.new-section-title {
  font-weight: bold;
  text-decoration: underline;
}

.new-input {
  width: 200px;
  margin-top: 10px;
}

.new-input.error {
  border: 2px red solid;
  color: red;
}

.new-input-modal-dropdown {
  position: relative;
  z-index: 900;
}

.new-input.long {
  width: 100%;
}

.new-date-div {
  width: fit-content;
  text-align: left;
  margin-top: 20px;
}

.new-date {
  background-color: white;
  padding: 10px;
}

.new-dynamic-field-div {
  margin-top: 30px;
  border: 1px solid;
  padding: 10px;
  position: relative;
}

.new-dynamic-field-grp {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.new-dynamic-field-add-del {
  cursor: pointer;
  margin-top: 20px;
}

.new-dynamic-field-add-del.no-margin {
  margin-top: 0;
}

.new-dynamic-field-del {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.new-markdown-div {
  margin-top: 30px;
}

.new-submit-btn {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  background-color: rgba(215, 11, 11, 0.824) !important;
  border: none;
}

.new-loading-btn {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  background-color: rgba(215, 11, 11, 0.824) !important;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.new-add-link {
  /* border-bottom: 1px solid; */
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
  color: blue;
}

.new-info-img {
  width: 300px;
  margin: 20px;
}

/*upload img*/
.form-uploaded-img-div {
  width: 200px;
  background-color: white;
  position: relative;
}

.form-uploaded-img {
  width: 100%;
  position: unset;
  z-index: 100;
}

.form-uploaded-img-div:hover .form-uploaded-img {
  opacity: 0.6;
}

.form-uploaded-img-copy-div {
  display: none;
}

.form-uploaded-img-div:hover .form-uploaded-img-copy-div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 101;
  background-color: rgba(57, 57, 57, 0.7);
}
