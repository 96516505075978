.profile-tabs-panel-div {
  padding: 10px;
  padding-top: 30px;
}

.upload-user-img-modal {
  position: relative;
  z-index: 1000;
}

.profile-tabs-tab {
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.profile-name-box {
  width: 25%;
  min-width: 300px;
  text-align: center;
  background-color: #30475e;
  color: #ffffff;
  padding: 10px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-photo-div {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}

.profile-photo-edit-logo {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 2px;
  background-color: white;
  cursor: pointer;
}

.profile-photo-div:hover .profile-photo-edit-logo {
  display: unset;
}

.profile-photo {
  width: 100%;
  border-radius: 50%;
}

.profile-name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.profile-edit-btn {
  margin-top: 15px;
  background-color: #f05454d4 !important;
  width: 200px;
}

.profile-edit-btn:hover {
  transform: scale(1.1);
}

.profile-info-box {
  width: 65%;
  min-width: 300px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-info-table {
  font-size: 15px;
  text-align: left;
}

.profile-info-table.left {
  font-weight: bold;
  width: 120px;
}
