.user-auth-modal {
  position: relative;
  z-index: 1001;
}

.user-auth-content {
  border-radius: 15px;
  box-shadow: rgba(74, 74, 74, 0.25) 0px 30px 60px -12px inset,
    rgba(74, 74, 74, 0.3) 0px 18px 36px -18px inset;
}

.user-auth-body {
  padding: 20px;
  background-color: #2c3e50;
  background: linear-gradient(to bottom, #283e51, #4b79a1);
}

.user-auth-body-content {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  text-align: center;
  width: 100%;
  margin: auto;
  color: #ffffff;
}

.user-auth-icon {
  height: 50px;
}

.user-auth-title {
  margin-top: 10px;
  font-size: 30px;
}

.user-auth-input {
  margin-top: 10px;
  width: 100%;
}

.user-auth-email-btn {
  margin-top: 10px;
  background-color: #ffffff;
  color: black;
}

.user-auth-or {
  margin-top: 10px;
}

.user-auth-google-btn {
  margin-top: 10px;
  background-color: white !important;
  color: black !important;
}

.user-auth-google-btn:hover {
  background-color: lightgrey !important;
}

.user-auth-facebook-btn {
  margin-top: 10px;
}

.user-auth-left-icon {
  height: 20px;
}

.user-auth-switch {
  margin-top: 10px;
  cursor: pointer;
}

.user-auth-switch:hover {
  text-decoration: underline;
}
