.page-div.policy {
  background-color: 
  rgba(255, 255, 255, 0.524);
}

.policy-title {
  text-align: center;
  font-size: 60px;
}

.policy-subtitle {
  font-size: 40px;
  margin-top: 15px;
}
.policy-subtitle.mid{
  font-size: 35px;
}
.policy-subtitle.small{
  font-size: 30px;
}

.policy-txt {
  font-size: 16px;
  margin-top: 10px;
}

.policy-bold {
  font-weight: 900;
}

.policy-markdown-txt {
  font-size: 16px;
  margin-top: 15px;
}

.policy-markdown-title {
  font-weight: 900 !important;
  font-size: 50px;
  margin-top: 30px;
}

.policy-markdown-title.h2 {
  font-weight: 900 !important;
  font-size: 40px;
}

.policy-markdown-title.h3 {
  font-weight: 900 !important;
  font-size: 30px;
  text-decoration: underline;
}
