.footer-div {
  height: 100%;
  min-height: 10vh;
  z-index: 299;
  background-color: #30475e;
  color: #dddddd;
  text-align: center;
  position: relative;
}

#footer-content {
  width: 90%;
  margin: auto;
}

.footer-icon {
  width: 35px;
  height: 35px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.footer-icon:hover {
  transform: scale(1.2);
}

.footer-text {
  margin-top: 5px;
  font-size: 12px;
}

.server-status {
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer-policy {
  color: white;
}
