/* .nav-drawer-content {
  background-color: #30475e !important;
} */

.nav-drawer-body {
  background-color: #2c3e50;
  background: linear-gradient(to bottom, #283e51, #4b79a1);
  height: 100%;
}

.nav-drawer-close {
  color: #ffffff;
}

.nav-drawer-close:hover {
  background-color: transparent !important;
}

.nav-drawer-timmy-logo-div {
  width: fit-content;
  aspect-ratio: 1/1;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  overflow: hidden;
  transition-duration: 500ms;
  border-radius: 50%;
  background-color: white;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18);
}

.nav-drawer-timmy-logo-div:hover {
  border-radius: 15px;
  scale: 1.3;
}

.nav-drawer-timmy-logo {
  position: relative;
  margin: auto;
  width: 80px;
  transition-duration: 700ms;
  z-index: 11;
}

.nav-drawer-timmy-logo-div:hover .nav-drawer-timmy-logo {
  transform: scale(1.3);
}

.nav-drawer-search {
  margin: auto;
  width: fit-content;
  margin-top: 20px;
}

.nav-drawer-box {
  width: fit-content;
  margin: auto;
  margin-top: 30px;
}

.nav-drawer-nav-div {
  margin-top: 30px;
}

.nav-drawer-links.main {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}

.nav-drawer-links-div {
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  z-index: 15;
  text-decoration: none;
}

.nav-drawer-links-border {
  position: absolute;
  height: 50px;
  top: 0;
  bottom: 0;
  left: -100;
  right: 0;
  /* margin: auto; */
  border-top: none;
  border-bottom: none;
  transition-duration: 300ms;
  z-index: 10;
}

.nav-drawer-links-div:hover .nav-drawer-links-border {
  border-top: 3px white solid;
  border-bottom: 3px white solid;
  left: 0;
}

.nav-drawer-links.main .mantine-NavLink-label {
  font-size: 20px;
  color: #dddddd;
}

.nav-drawer-links.main:hover {
  background-color: transparent !important;
  text-decoration: underline;
  transform: scale(1.2);
}

.nav-drawer-links.sub {
  text-align: center;
}

.nav-drawer-links.sub .mantine-NavLink-label {
  text-align: center;
  font-weight: bold;
  font-family: "Glametrix";
  font-size: 18px;
}

.nav-drawer-links:hover {
  background-color: transparent !important;
  text-decoration: underline;
}
