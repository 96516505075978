/*general*/
.home-top-div {
  width: 98%;
  margin: auto;
  overflow: hidden;
  background-color: transparent;
}

.home-top-grp {
  width: 100%;
  aspect-ratio: 21/9;
}

.home-top-card {
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.home-top-card.left {
  background-color: #ffffff;
}

.home-top-card.right {
  background-color: #4a6e93;
}

.home-top-card.mid {
  background-color: #ffffff;
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/timmycards-website-public/o/Home%2Fred_pikachu.png?alt=media");
  background-size: 165%;
  background-position: 7% -70%; */
}

.home-top-mid-back {
  position: absolute;
  width: 160%;
  left: -5%;
  top: -2%;
  z-index: 95;
  opacity: 0.8;
}

/*img*/
.home-top-img {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  height: 30%;
  transition-duration: 500ms;
}

.home-top-img.left {
  top: 2%;
}

.home-top-img.mid {
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}

.home-top-img.right {
  bottom: 2%;
}

/*content*/
.home-top-content-div {
  position: absolute;
  background-color: #4a6e93;
  height: 80%;
  width: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  color: #ffffff;
}

.home-top-content-div.left {
  bottom: 0;
  background: linear-gradient(to bottom left, transparent 50%, #4a6e93 50%)
      no-repeat,
    linear-gradient(to bottom left, transparent 0.1%, #4a6e93 0.1%) no-repeat;
  background-size: 103% 25.15%, 103% 75.15%;
  background-position: 0% 0%, 0% 100%;
  padding-top: 20%;
}

.home-top-content-div.right {
  background-color: #ffffff;
  top: 0;
  background: linear-gradient(to top right, transparent 50%, #ffffff 50%)
      no-repeat,
    linear-gradient(to top right, transparent 0.1%, #ffffff 0.1%) no-repeat;
  background-size: 103% 25.15%, 103% 75.15%;
  background-position: 30% 100%, 0% 0%;
  padding-bottom: 30%;
  color: #30475e;
}

.home-top-content-mid {
  position: absolute;
  z-index: 99;
  height: 30%;
  width: 100%;
  background-color: #4a6e93;
  transition-duration: 500ms;
}

.home-top-mid-line {
  position: absolute;
  height: 1.5%;
  right: -5%;
  background-color: #4a6e93;
  transform: rotate(14deg);
  z-index: 99;
}

.home-top-mid-line.first {
  width: 30%;
  bottom: 41%;
}

.home-top-mid-line.second {
  width: 42.5%;
  bottom: 37%;
}

.home-top-mid-line.third {
  width: 55%;
  bottom: 33%;
}

.home-top-mid-line.fourth {
  width: 67.5%;
  bottom: 29%;
}

.home-top-mid-line.fifth {
  width: 80%;
  bottom: 25%;
}

.home-top-mid-line.sixth {
  width: 92.5%;
  bottom: 21%;
}

.home-top-content-mid.btm {
  bottom: 0;
  background: linear-gradient(to bottom left, transparent 50%, #4a6e93 50%)
      no-repeat,
    linear-gradient(to bottom left, transparent 0.1%, #4a6e93 0.1%) no-repeat;
  background-size: 103% 66.15%, 103% 34.15%;
  background-position: 0% 0%, 0% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

/*content's children*/
.home-top-content-title {
  font-size: 35px;
  /* color: #ffffff; */
  font-weight: bold;
  text-align: center;
}

.home-top-content-title.mid {
  margin-top: 15%;
}

.home-top-content-txt {
  margin-top: 20px;
  font-size: 16px;
  text-align: justify;
}

.home-top-content-btn {
  margin-top: 25px;
  font-size: 16px;
  background-color: tomato;
  text-decoration: none;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  box-shadow: none;
  border: none;
  border-radius: 999px;
  width: fit-content;
  color: #ffffff;
  transition-duration: 500ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home-top-content-txt.mid,
.home-top-content-btn.mid {
  position: absolute;
  bottom: -100%;
  padding: 5%;
  left: 0;
  right: 0;
  margin: auto;
  transition-duration: 500ms;
}

.home-top-content-btn.mid {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

/*hover*/
.home-top-card:hover .home-top-img {
  height: 35%;
}

.home-top-card.mid:hover .home-top-content-mid.btm {
  height: 60%;
  background-size: 103% 33.2%, 103% 67%;
  padding: 5%;
}

.home-top-card.mid:hover .home-top-content-txt.mid {
  /* position: unset; */
  bottom: 35%;
}

.home-top-card.mid:hover .home-top-content-btn.mid {
  bottom: 15%;
}

.home-top-card.mid:hover .home-top-content-title.mid {
  display: none;
}

.home-top-content-btn:hover {
  transform: scale(1.1);
}
