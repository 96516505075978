@font-face {
  font-family: "Milkalte";
  src: url(./fonts/Milkalte-4BmnK.otf);
}

@font-face {
  font-family: "Legibility";
  src: url(./fonts/Cardiff-5a2v.ttf);
}

@font-face {
  font-family: "Legibility";
  src: url(./fonts/Legibility-lwdq.ttf);
}

@font-face {
  font-family: "Fonarto";
  src: url(./fonts/Fonarto-dqPx.otf);
}

@font-face {
  font-family: "Jakarta Sans";
  src: url(./fonts/PlusJakartaSans-VariableFont_wght.ttf);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  margin: 0;
  padding: 0;
}

.app-div {
  background-color: #dddddd;
  position: relative;
  font-family: "Jost";
  font-size: 16px;
}

.main-body {
  height: 100%;
  min-height: 84vh;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  max-width: 1280px;
  padding-bottom: 1vh;
  padding: 20px;
  position: relative;
  /* background-color: rgb(255, 255, 255, 0.3); */
}

.top-app-div {
  margin: auto;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.page-div {
  padding: 15px;
  /* padding-top: 30px;
  padding-bottom: 30px; */
  position: relative;
}

.page-title {
  text-align: center;
  font-size: 50px;
  font-family: "Jakarta Sans";
  font-weight: bold;
  color: #30475e;
}

.page-date {
  font-size: 20px;
}

.comp-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  font-family: "Jakarta Sans";
  color: #30475e;
}

.page-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.warning {
  background-color: red;
  text-align: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  margin: auto;
  margin-top: 30px;
  font-size: 20px;
  color: white;
}

.admin-new-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 500;
}

.page-breadcrumbs {
  margin-bottom: 20px;
  text-decoration: none;
}

.mantine-Breadcrumbs-breadcrumb {
  text-decoration: none;
  color: #30475e !important;
}

.mantine-Breadcrumbs-breadcrumb:hover {
  transform: scale(1.1);
}

.v-ads-div {
  width: 100%;
}

.v-ads {
  position: fixed;
  height: 75vh;
  top: 15vh;
  /* background-color: black; */
  opacity: 0.8;
  width: 150px;
}

.v-ads.left {
  left: 10px;
}

.v-ads.right {
  right: 10px;
}

.basic-anchor {
  text-decoration: none;
  color: inherit;
}

.basic-anchor:hover {
  color: inherit;
}

.basic-anchor.about-btn {
  width: 100%;
}

.basic-anchor.article-card {
  margin: auto;
  margin-top: 20px;
  width: 32%;
  min-width: 200px;
  aspect-ratio: 1/1;
}

.basic-anchor.home-cards {
  width: 30%;
  min-width: 300px;
  padding: 0;
}

.basic-anchor.past-tourney-cards {
  min-width: 300px;
}

.banner-ad {
  width: 98%;
  margin: auto;
}
