.header {
  position: sticky;
  top: 0;
  z-index: 299;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.header-div {
  position: sticky;
  top: 0;
  z-index: 299;
  /* background-color: #30475e; */
  background: #536976; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #30475e,
    #536976
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #30475e,
    #536976
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #dddddd;
  min-height: 6vh;
  height: fit-content;
  padding: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header.hide {
  top: -6vh;
}

#nav-link-group {
  margin-right: auto;
  margin-left: 10px;
}

#nav-link-tabs-list {
  border: none !important;
}

#nav-link-timmy {
  cursor: pointer;
  font-size: 23px;
  height: 5vh;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timmy-logo {
  height: 100%;
}

.timmy-logo:hover {
  transform: scale(1.1);
}

.nav-link-btn {
  background-color: transparent !important;
  color: #dddddd !important;
  border: none !important;
  padding-top: 6px;
  padding-bottom: 6px;
  border: none !important;
  font-size: 20px;
}

.nav-link-btn:hover {
  /* background-color: transparent; */
  padding-bottom: 12px;
}
.nav-link-btn.active {
  /* background-color: transparent; */
  border-bottom: solid 1px black;
}

#nav-user-group {
  margin-left: right;
}

#nav-user-icon {
  height: 30px;
  cursor: pointer;
}
