.cardsets-card-group {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
  grid-gap: 12px;
  justify-content: center;
  padding: 0;
}

.cardsets-card {
  width: 250px;
  height: 300px;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  background-color: #30475e;
  box-shadow: rgba(103, 6, 6, 0.19) 0px 10px 20px,
    rgba(103, 6, 6, 0.23) 0px 6px 6px;
}

.cardsets-card:hover {
  transform: scale(1.1);
}

.cardsets-logo {
  width: 250px;
  height: 200px;
  object-fit: contain;
  padding: 5px;
  background-color: #ffffff;
}

.cardsets-name-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: max-content;
}

.cardsets-name {
  font-size: 25px;
  color: #ffffff;
  padding-top: 10px;
}
