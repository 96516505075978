#cardset-logo {
  width: 250px;
  margin-bottom: 20px;
}

.cardset-search-bar {
  width: 50%;
  margin: auto;
  margin-top: 30px;
}

.cardset-cards-group {
  margin-top: 30px;
  overflow: visible;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15%, 150px));
  grid-gap: 16px;
  justify-content: center;
  padding: 0;
}
