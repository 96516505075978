/*title*/
.article-title-div {
  width: 100%;
  aspect-ratio: 21/9;
  position: relative;
  background-color: #30475e;
}

.article-title-img {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  object-fit: cover;
}

#article-title {
  padding: 20px;
  border-radius: 15px;
  color: #30475e;
  background-color: #ffffffe2;
  width: 80%;
  min-width: 300px;
  text-align: justify;
  margin-top: 30px;
  font-size: 60px;
  font-family: "Helvetica Neue";
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 105;
}

/*content*/
.article-content-th {
  padding: 20px;
  height: 100%;
}

/*Left div*/
.article-content-th.left {
  /* width: 80%; */
  min-width: 300px;
  background-color: #ffffffc6;
}

.article-likes {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
}

.article-like-btn {
  cursor: pointer;
  height: 20px;
  margin-left: 10px;
}

.article-tags {
  margin-top: 20px;
}

.article-body {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
}

#article-likes {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.article-react-markdown-img {
  width: max-content;
  max-width: 100%;
  object-fit: contain;
}

.article-react-markdown-content-title-1 {
  font-weight: bold;
  text-decoration: underline;
}

.article-react-markdown-content-title-2 {
  font-weight: bold;
  margin-top: 10px;
}

.article-react-markdown-text {
  text-align: justify;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 20px;
}

/*Right div*/
.article-content-th.right {
  width: 200px;
  background-color: #30475e90;
  position: relative;
}

.article-content-div {
  position: absolute;
  top: 20px;
  margin: auto;
  left: 0;
  right: 0;
  width: calc(100% - 20px);
}

.article-author-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ffffff;
  margin-top: 12px;
}

.article-author-grp {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ffffff;
  margin-top: 12px;
}

.article-author {
  font-size: 20px;
  color: #ffffff;
}

.article-tags-div {
  margin-top: 30px;
}

.article-tag-btn {
  text-align: left;
  margin-top: 12px;
  font-size: 16px;
}
