.card-info-div {
  width: 60%;
  min-width: 300px;
  padding: 10px;
  /* border-radius: 15px; */

  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-t {
  margin-top: 30px;
}

.card-id-name {
  text-align: center;
  font-weight: bold;
  font-family: "Cardiff";
}

.card-info-table-head {
  font-weight: bold;
  text-align: justify;
  width: 20%;
}

.card-info-table-body {
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  padding-left: 20px;
  padding-right: 20px;
}

.card-info-energy {
  max-width: 20px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.card-info-list-item {
  padding-right: 10px;
}
