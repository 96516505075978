.home-top-m-card {
  width: 98%;
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  position: relative;
  padding: 0;
  color: #ffffff;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.home-top-m-img {
  margin: auto;
  height: 150px;
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
}

.home-top-m-card-content {
  margin-top: 80px;
  padding: 10px;
  padding-top: 90px;
  padding-bottom: 20px;
  background: linear-gradient(to bottom left, transparent 50%, #4a6e93 50%)
      no-repeat,
    linear-gradient(to bottom left, transparent 0.1%, #4a6e93 0.1%) no-repeat;
  background-size: 103% 25.15%, 103% 75.15%;
  background-position: 0% 0%, 0% 100%;
}

.home-top-m-card-title {
  font-size: 40px;
  text-align: center;
}

.home-top-m-card-text {
  text-align: justify;
  margin-top: 12px;
  font-size: 16px;
}

.home-top-m-card-btn {
  margin-top: 20px;
  background-color: tomato;
  text-decoration: none;
}
