.loading-div {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background-color: #30475ece;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-img-box {
  width: 20%;
  min-width: 200px;
  color: white;
}

.loading-img-div {
  width: 100%;
  aspect-ratio: 1/1;
  background: radial-gradient(circle at 20% 20%, #ffffff, #aaaaaa);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.loading-img {
  width: 80%;
}
