.search-result-tabs-list {
  margin-top: 30px;
}

.search-result-tab {
  font-size: 20px;
  padding: 3px;
  width: 150px;
  border: none;
  border-radius: 20px;
  color: #30475e;
  background-color: #ffffff;

  &[data-active] {
    background-color: #30475e;
    color: #ffffff;
  }
}

.search-result-tab-icon {
  margin-right: 5px;
}

.search-result-panels-div {
  margin-top: 30px;
}

.search-result-not-found {
  font-size: 20px;
  font-weight: bold;
}

.search-result-loader-grp {
  margin-top: 30px;
}
