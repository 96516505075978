.tournaments-card-grp {
  margin-top: 30px;
}

.tournaments-card {
  width: 350px;
  min-height: 300px;
  cursor: pointer;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 30px;
}

.tournaments-card.disabled {
  cursor: default;
  opacity: 0.75;
}

.tournaments-card:hover {
  transform: scale(1.1);
}

.tournaments-card.disabled:hover {
  transform: none;
}

.tournaments-card-img {
  object-fit: contain;
  background-color: #30475e;
}

.tournaments-card-title-grp {
  margin-top: 20px;
}

.tournaments-card-title {
  font-size: 18px;
}

.tournaments-card-text {
  text-align: justify;
  margin-top: 10px;
  font-size: 15px;
}
