.page-div.support {
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.support-title {
  font-size: 40px;
}

.support-txt {
  font-size: 16px;
  margin-top: 15px;
}