.top-div.hide {
  right: -65px;
}

.top-div {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 8px;
  cursor: pointer;
  z-index: 999;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.top-up {
  position: absolute;
  top: 2px;
}

.top-img {
  width: 60px;
  position: absolute;
  bottom: -10px;
  right: -7px;
}

.top-text {
  color: #0b7141;
  font-size: 12px;
}
