.edit-profile-div {
  margin: auto;
  width: 98%;
  max-width: 700px;
}

.edit-profile-input {
  margin-top: 10px;
}

.edit-profile-submit {
  margin: auto;
  margin-top: 20px;
}
