.card-card {
  width: 150px;
  margin: 5px;
  cursor: pointer;
  background-color: transparent;
}

.card-card:hover {
  transform: scale(1.1);
}

.card-card-img {
  width: 100%;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.card-card-text {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border-top: 4px solid #30475e;
  border-bottom: 4px solid #30475e;
}

.card-card-title {
  text-align: center;
  padding-top: 10px;
}
