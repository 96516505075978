.store-body {
  position: relative;
}

.store-table {
  width: 90%;
  margin: auto;
}

.store-table-title {
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
  height: 100%;
  width: fit-content;
  max-width: 30%;
}

.store-table-content {
  padding-left: 3px;
  padding-right: 3px;
}

.store-game-img {
  height: 25px;
  margin-left: 10px;
}

.store-link-icon {
  height: 30px;
  cursor: pointer;
}
