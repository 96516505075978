.past-tournaments-select-grp {
  margin-top: 20px;
}

.past-tournaments-select {
  width: 200px;
}

.past-tournamets-cards-grp {
  width: 98%;
  margin: auto;
  margin-top: 30px;
  overflow: visible;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  grid-gap: 12px;
  justify-content: center;
  padding: 0;
}

.past-tournaments-card {
  width: 300px;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  position: relative;
  z-index: 100;
  padding: 10px;
  text-align: start;
  overflow: hidden;
}

.past-tournaments-card:hover {
  transform: scale(1.05);
  z-index: 101;
}

.past-tournaments-card-img-div {
  width: 25%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    0 8px 16px rgba(0, 0, 0, 0.11);
  transition-duration: 1000ms;
  transform-style: preserve-3d;
}

.past-tournaments-card-img {
  width: 80%;
  margin: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.past-tournaments-gif-div {
  width: 80px;
  aspect-ratio: 1/1;
  overflow: hidden;
  position: absolute;
  right: -80px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.past-tournaments-gif {
  width: 100%;
}

.past-tournaments-card:hover .past-tournaments-gif-div {
  right: 10px;
}

.past-tournaments-card-loc {
  font-size: 20px;
  font-weight: bold;
}
