.latest-home-div {
  margin-top: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 16px;
  border-radius: 15px;
}

.latest-home-title {
  width: 100%;
  font-family: "Cardiff";
}

.latest-home-paper {
  margin: auto;
  width: 100%;
  /* border-radius: 20px; */
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  /* background-color: #30475e; */
  background-color: transparent;
  /* border-top: 3px solid black;
  border-bottom: 3px solid black;
  box-shadow: 0 30px 20px -30px rgba(0, 0, 0, 0.45) inset,
    0 -30px 20px -30px rgba(0, 0, 0, 0.45) inset; */
  position: relative;
}

.latest-home-paper-border {
  position: absolute;
  height: 6px;
  width: 100%;
  background-image: linear-gradient(to right, #283e51, #4b79a1);
  transition-duration: 500ms;
}

.latest-home-paper-border.top {
  top: 0;
}

.latest-home-paper-border.btm {
  bottom: 0;
}

.latest-home-grid {
  border-radius: 20px;
}

.latest-home-top {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 30px;
  aspect-ratio: 1/1;
  /* background-color: #f05454d4;
  color: white; */
}

.latest-home-cardset-div {
  width: 90%;
  aspect-ratio: 1/1;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
  z-index: 110;
}

.latest-home-cardset-border {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 15px;
  transition-duration: 300ms;
  z-index: 99;
}

.latest-home-cardset-div:hover .latest-home-cardset-border {
  border: 4px double tomato;
  transform: scale(1.1);
}

.latest-home-cardset-div:hover .latest-home-cardset-border.btm {
  border: 3px double tomato;
  transform: scale(1.15);
}

.latest-home-top-text {
  position: absolute;
  bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  background-color: white;
  border-top: 4px solid black;
  border-bottom: 4px solid black;
  z-index: 13;
}

.latest-home-top-text.list {
  font-size: 12px;
  bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.latest-home-top-img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  padding: 20px;
  margin: auto;
  position: relative;
  z-index: 10;
  transition-duration: 300ms;
  animation: fade-in 1.5s linear;
}

.latest-home-top:hover .latest-home-top-img {
  transform: scale(1.2);
  z-index: 12;
}

.latest-home-content {
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.latest-home-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.latest-home-cardsets-grp {
  margin-top: 20px;
  margin-bottom: 20px;
}

.latest-home-cardset-div.list {
  width: 15%;
}

.latest-home-cardset-div.list:hover .latest-home-cardsets-card-img {
  z-index: 12;
  transform: scale(1.2);
}

.latest-home-cardsets-card-img {
  width: 90%;
  margin: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
  position: relative;
  z-index: 10;
  transition-duration: 300ms;
  animation: fade-in 1.5s linear;
}

.latest-home-cardsets-card-title {
  font-size: 30px;
  text-align: center;
  font-family: "Legibility";
}
