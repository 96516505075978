.card-skills-div {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: 100%;
  max-width: 800px;
}

.card-skills-section-title-div {
  position: relative;
  /* padding: 3px; */
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 5px;
}

.card-skills-section-title-border.top {
  width: 85%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: #30475e; */
  border-top: 2px solid #30475e;
  border-left: 2px solid #30475e;
  z-index: 50;
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
}

.card-skills-section-title-border.btm {
  width: 85%;
  height: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-color: rgb(205, 100, 100); */
  border-bottom: 2px solid rgb(215, 11, 11);
  border-right: 2px solid rgb(215, 11, 11);
  z-index: 50;
  /* border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px; */
}

.card-skills-section-title {
  font-size: 20px;
  font-weight: bold;
  color: black;
  position: relative;
  z-index: 51;
}

.card-skills-energy {
  width: 20px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.card-atk-div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-atk-name {
  font-size: 18px;
  font-weight: bold;
}

.card-atk-dmg {
  margin-left: 20px;
}

.card-atk-text {
  margin-top: 5px;
  text-align: justify;
}
