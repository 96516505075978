#add-info-form {
  width: 40%;
  min-width: 400px;
  margin: auto;
}

.add-info-input {
  width: 100%;
  margin: auto;
  margin-top: 15px;
}

.add-info-input-small {
  width: 48%;
  min-width: 196px;
  margin: auto;
}

.add-info-submit-btn {
  margin-top: 20px;
  background-color: #30475e;
  color: #ffffff;
}
