.card-div {
  background-color: #ffffff;
  width: fit-content;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 15px;
  padding: 10px;
}

.card-grp {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.card-grp.btm {
  padding-left: 10px;
  padding-right: 10px;
}

.card-img-div {
  width: 30%;
  min-width: 300px;
  padding: 30px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-img {
  width: 100%;
  cursor: pointer;
}

.card-img:hover {
  transform: scale(1.1);
}
