.user-drawer {
  position: sticky;
  z-index: 1000;
}

.user-drawer-content {
  background-color: #2c3e50;
  background: linear-gradient(to bottom, #283e51, #4b79a1);
  text-align: center;
  padding: 30px;
  height: 100%;
}

.user-drawer-close {
  color: #ffffff;
}

#user-drawer-icon {
  width: 100px;
  margin-top: 30px;
  border-radius: 50%;
  background-color: #ffffff;
}

.user-drawer-name {
  color: #ffffff;
  font-size: 30px;
  margin-top: 30px;
  font-family: "Milkalte";
}

.user-drawer-btn-box {
  padding-bottom: 30px;
  border-bottom: solid 2px #ffffff;
}

.user-drawer-btn {
  margin-top: 20px;
  background-color: #dddddd !important;
  color: black;
}

.user-drawer-btn:hover {
  transform: scale(1.1);
}

.user-drawer-logout-btn {
  margin-top: 20px;
  background-color: rgba(215, 11, 11, 0.824) !important;
  color: #ffffff;
}

.user-drawer-logout-btn:hover {
  transform: scale(1.1);
}

.user-drawer-text {
  margin-top: 15px;
  color: #dddddd;
  font-size: 14px;
  cursor: pointer;
}

.user-drawer-text:hover {
  text-decoration: underline;
}
