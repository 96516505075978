.distribution-chart-div {
  width: 100%;
  height: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.distribution-title-div {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.distribution-title {
  margin-right: auto;
  font-family: "Cardiff";
}

.distribution-sort {
  width: fit-content;
}

.distribution-tabs {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 0px;
  margin-top: 10px;
  border-radius: 15px;
}

.distribution-t.mobile {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  /* padding: 20px; */
  margin-top: 30px;
}

.distribution-tabs-list {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.distribution-tab-title {
  padding: 20px;
  font-size: 18px;
  width: 50%;
  background-color: #30475e;
  color: #ffffff;
  &[data-active] {
    border: none;
    /* border-top: solid 2px #30475e;
    border-left: solid 2px #30475e;
    border-right: solid 2px #30475e; */
    background-color: #ffffff;
    color: #30475e;
  }
}

.distribution-tab-title.left {
  border-top-left-radius: 15px;
  border-top-right-radius: 0;
}

.distribution-tab-title.right {
  border-top-left-radius: 0;
  border-top-right-radius: 15px;
}

.distribution-tab-title:hover {
  font-weight: bold;
}

.distirbution-tabs-panel-div {
  width: 100%;
  aspect-ratio: 2/1;
  padding: 30px;
}

.distribution-tabs-panel {
  width: 100%;
  aspect-ratio: 16/6;
}

.distribution-tabs-panel.vertical {
  width: 100%;
  height: 100%;
}

.distribution-cont {
  margin-top: 30px;
}

.distribution-cont.vertical {
  margin-top: 0px;
}

.distribution-bar-chart {
  margin-bottom: 10%;
}

.distribution-bar-tooltip {
  text-align: center;
  font-weight: bold;
  background-color: #30475ed9;
  color: #ffffff;
  padding: 20px;
  border-radius: 15px;
}

.distribution-chart-bar-label {
  font-size: 12px;
  font-weight: bold;
}

.distribution-bar-tooltip-text.deck {
  font-size: 20px;
  font-weight: bolder;
}

.distribution-sprite-grp {
  height: max-content;
}

.distribution-sprite.chart {
  width: 100px;
}

.distribution-sprite.table {
  width: 60px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.distribution-sprite.table:hover {
  transform: scale(1.5);
}

.distribution-tick {
  cursor: pointer;
  font-size: 15px;
  color: black;
  font-weight: bold;
}

.distribution-tick:hover {
  font-size: 20px;
}

.distribution-t {
  width: 98%;

  margin: auto;
  margin-top: 30px;
  height: fit-content;
  font-size: 15px;
}

.distribution-t-head {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.distribution-t-body {
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
}

.distribution-t-head.one {
  width: 20%;
}

.distribution-t-head.two {
  width: 40%;
}

.distribution-t-head.three {
  width: 20%;
}

.x-axis-div {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: 30px;
  margin-top: -30px;
  margin-bottom: -30px;
}

.x-axis-sprite-img-div {
  margin: auto;
  margin-top: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px; */
  overflow: hidden;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  cursor: pointer;
}

.x-axis-sprite-img-div:hover {
  overflow: visible;
}

.x-axis-sprite {
  width: 100%;
  /* position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto; */
}

.x-axis-sprite-img-div:hover .x-axis-sprite {
  transform: scale(1.5);
}

.x-axis-sprite.btm {
  margin-top: 0px;
}

.y-axis-div {
  width: 10%;
}

.y-axis-sprite-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
