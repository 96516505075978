.home-cards-div {
  width: 98%;
  margin: auto;
}

.home-card {
  /* width: 30%;
  min-width: 300px; */
  cursor: pointer;
  height: max-content;
  min-height: 340px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.home-card-section {
  position: relative;
  overflow: hidden;
}

.home-card-img {
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
  z-index: 10;
  transform: scale(1.1);
  object-fit: cover;
}

.home-card-rect {
  width: 100%;
  aspect-ratio: 16/9;
  position: absolute;
  border: 5px solid transparent;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 11;
  transition-property: all;
  /* transition-timing-function: cubic-bezier(1, 1, 1, 1); */
  transition-duration: 500ms;
}

.home-card:hover .home-card-rect {
  width: 90%;
  aspect-ratio: 16/9;
  border: 7px solid #eeeeee;
}

.home-card-title {
  margin-top: 10px;
  font-family: "Cardiff";
  font-size: 27px;
}

.home-card-text {
  margin-top: 5px;
  /* text-align: justify; */
  font-family: "Legibility";
  font-size: 15px;
  color: #616060;
}
