.upload-user-img-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.user-photo {
  height: 200px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin-top: 20px;
}

.upload-user-img-title {
  text-align: center;
  font-size: 30px;
  font-family: "Cardiff";
  font-weight: bold;
}

.upload-user-img-input {
  width: 90%;
  margin-top: 20px;
}

.upload-user-img-btn {
  margin-top: 20px;
  background-color: rgba(215, 11, 11, 0.824) !important;
}
