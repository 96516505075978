.stores-search-bar {
  width: 50%;
  min-width: 300px;
  margin: auto;
  margin-top: 20px;
}

#stores-filter-div {
  margin: auto;
  margin-top: 20px;
}

.stores-filter {
  width: 20%;
  min-width: 300px;
}

#stores-content {
  margin-top: 30px;
}

.stores-accordion {
  width: 59%;
  min-width: 350px;
  height: 500px;
  overflow: auto;
  border-radius: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

#store-map {
  width: 39%;
  min-width: 350px;
  height: 500px;
  border: none;
  border-radius: 15px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
