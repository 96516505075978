/* .articles-tabs {
  border-bottom: 2px solid #30475e;
} */

.articles-tab {
  padding: 8px;
  padding-left: 30px;
  padding-right: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.articles-tabs-panels {
  margin-top: 30px;
}
