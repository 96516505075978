.about-header-div {
  margin: auto;
  width: 95%;
  aspect-ratio: 4/1;
  position: relative;
  background: #cc95c0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #7aa1d2,
    #dbd4b4,
    #cc95c0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #7aa1d2,
    #dbd4b4,
    #cc95c0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* border-radius: 15px; */
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.about-logo-div {
  height: 70%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding: 3%;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  background-color: white;
  transition-duration: 1500ms;
  transform-style: preserve-3d;
}

.about-header-div:hover .about-logo-div {
  height: 80%;
  transform: rotatey(360deg);
}

.about-logo {
  width: 100%;
}

.about-content-div {
  margin: auto;
  margin-top: 30px;
  width: 95%;
}

.about-content-div.second {
  padding: 30px;
  background-color: #30475ed3;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.about-grid {
  margin-top: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
  background-color: white;
}

.about-grid-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.about-grid-col.first {
  width: 49%;
  aspect-ratio: 16/9;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.about-grid-col.second {
  background-color: white;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.about-background-img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
  z-index: 10;
}

.about-content-info {
  position: relative;
  width: 90%;
  z-index: 11;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 15px;
}

.about-content-title {
  text-align: center;
  font-family: "Cardiff";
  font-size: 30px;
}

.about-content-title.second {
  color: white;
}

.about-content-subtitle {
  height: fit-content;
  width: fit-content;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Cardiff";
  font-size: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #30475e;
  color: white;
  border-radius: 15px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
}

.about-content-text {
  font-family: "Legality";
}

.about-content-img {
  width: 100%;
  aspect-ratio: 16/8;
  object-fit: cover;
}

.about-flip-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.about-grid-col.flip {
  /* background-color: white; */
  perspective: 1000px;
}

.grid-second-front {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.grid-second-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  background-color: #30475e !important;
}

.about-grid.second:hover .about-flip-inner {
  transform: rotateY(180deg);
}

.grid-second-back {
  transform: rotateY(180deg);
}

.about-flip-btn {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: fit-content;
  border: none;
  width: 80%;
  color: #30475e;
  background-color: white;
  transition-duration: 500ms;
  font-size: 20px;
  border-radius: 20px;
}

.about-flip-btn:hover {
  border-radius: 5px;
  background-color: rgb(205, 100, 100);
  color: white;
}
